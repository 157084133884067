body .VeniceTheme {
    background-color: #f1f1f1;
    /* padding-top: var(--height); */
    margin-top: calc(-1 * var(--height));
}

.container {
    max-width: 1200px;
    position: relative;
    width: 100%;
}

.venice_hero_slider .venice_hero_img img {
    /* height: 800px; */
    width: 100%;
    padding-top: var(--height);
}

.venice_hero_slider {
    position: relative;
    padding: 0;
    width: 100%;
    max-width: 1470px;
    margin: 0 auto;
    display: block;
}

.venice_hero_slider h1 {
    text-transform: capitalize;
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #fff;
}

.venice_hero_slider p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #fff;
}

.venice_hero_slider button {
    background: #333;
    color: #fff;
    border-radius: 0px;
    padding: 11px 25px;
    min-width: 200px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-top: 25px;
    transition: all .5s;
    border: 0;
}
.venice_hero_slider button:hover{
    background: var(--primary);
}
.venice_hero_slider .banner-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.venice_hero_slider .banner-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    padding: 150px;
    margin-top: -30px;
}

.venice_hero_slider .banner-content>div {
    width: 100%;
}

/* ==================== */

.vanice-DiamondRing {
    position: relative;
    padding-top: 100px;
    padding-bottom: 250px;
}

.vanice-DiamondRing img {
    position: absolute;
    top: -80px;
}

.vanice-DiamondRing .row {
    position: relative;
}

.vanice-DiamondRing .WordStyle {
    color: #e4e4e4;
    font-family: 'Nunito Sans';
    font-size: 350px;
    line-height: 360px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: none;
}

.vanice-DiamondRing .vanice-DiamondRing-subtitle {
    color: #cea874;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.vanice-DiamondRing .vanice-DiamondRing-title {
    color: #000000;
    font-family: Nunito Sans;
    font-size: 45px;
    line-height: 55px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: none;
}

.vanice-DiamondRing .vanice-DiamondRing-text {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
}

.vanice-DiamondRing .vanice-DiamondRing-btn a:hover {
    background: #cea874;
    border-color: #cea874;
    color: #fff;
}
.vanice-DiamondRing .vanice-DiamondRing-btn a {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #000000;
    font-size: 13px;
    text-align: center;
    justify-content: center;
    width: 70%;
    line-height: 48px;
    height: 48px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: block;
    transition: all 0.5s ease;
}

.textBox {
    position: absolute;
    top: 30%;
    left: 120px;
}

/* ================== */

.DiamondCollection {
    background-image: url('../../../../Assets/images/venice/h1-img-4.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px;
    margin: auto;
    max-width: 1920px;
}

.DiamondCollection .contentBox {
    padding-left: 113px;
    padding-top: 60px;
}

.DiamondCollection .contentBox h2 {
    color: #cea874;
    font-family: var(--font_1);
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 30px;
    margin-top: 30px;
}

.qode_video_box_button_holder {
    position: absolute;
    top: 90%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 44;
    cursor: pointer;
}

.qode_video_box_button_holder .qode_video_box_button {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 80px;
    height: 80px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    box-sizing: border-box;
    transition: background-color .15s ease-out;
}

.qode_video_box_button_arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -50%);
    border-top: 12px solid transparent;
    border-left: 18px solid #303030;
    border-bottom: 12px solid transparent;
    box-sizing: border-box;
}

/* ===================== */

.vanice-DiamondRing.ExploreCollection {
    padding-bottom: 0;
}

.sectionTitle {
    font-family: var(--font_1);
    font-size: 45px;
    line-height: 55px;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: none;
    text-align: center;
    margin-bottom: 50px;
}

.ExploreCollection img {
    position: static;
    filter: grayscale(1);
    transition: all .3s ease;
}

.ExploreCollectionImgBox {
    display: block;
    overflow: hidden;
}

.ExploreCollectionImgBox:hover img {
    filter: grayscale(0);
    /* opacity: .7; */
    transform: scale(1.09);
    overflow: hidden;
}

.ExploreCollection_innerBox {
    padding: 50px 0;
}

.vanice-DiamondRing.ExploreCollection .ExploreCollection_innerBox.container:nth-child(odd) .row {
    flex-direction: row-reverse;
}

/* ====================== */
.venice_DiamondShape {
    padding: 100px 0;
}
.venice_DiamondShape .container-fluid{
    max-width: 1920px;
    margin: auto;
}
.venice_DiamondShape .swiper-slide img {
    display: block;
    width: 100%;
}

/* =================== */

.venice_Customer .venice_Customer_inner {
    background-image: url('../../../../Assets/images/venice/h1-img-5.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
    max-height: 500px;
    margin-bottom: 100px;
}

.venice_Customer .venice-custome-container.container {
    padding: 40px;
    background: #fff;
    position: relative;
    min-height: 260px;
}

.venice_Customer .venice_customer_review_slide_item h3 {
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
}

.venice_Customer .venice_customer_review_slide_item p,
.venice_Customer .venice_customer_review_slide_item p a {
    color: #555;
    font-size: 15px;
}

.venice_Customer .venice-custome-container .slick-arrow {
    color: #333;
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    padding: 5px;
    opacity: 0.8;
}

.venice_Customer .venice-custome-container .slick-dots li {
    margin: 0;
}

.venice_Customer .venice-custome-container .slick-dots li button:before {
    font-size: 10px;
}

.venice_customer_review_slider .slick-list {
    max-width: 900px;
    margin: auto;
}
.venice_customer_review_slide_img img {
    width: 150px;
    margin: 10px auto 0;
    cursor: pointer;
}

/* ================== */
.insta-iframe {
    background-color: var(--font_1);
}

.insta-section {
    max-width: 1800px;
    margin: auto;
    padding-bottom: 30px;
}

@media(max-width: 650px) {
    .insta-iframe {
        height: 180px;
    }

    .holiday_highlight_section {
        margin-top: 50px !important;
    }

    .holiday_highlight_section:before .textBox {
    margin-top: 30px !important;
    }

    .holiday_highlight_section img {
    max-width: 100% !important;
    }
}

@media(min-width: 650px) and (max-width:991px) {
    .insta-iframe {
        height: 149px;
    }
}

@media(min-width: 991px) and (max-width:1279px) {
    .insta-iframe {
        height: 173px;
    }
    .popupImageLoad .modal-dialog {
        max-height: 500px;
        max-width: 500px;
    }
}

@media(min-width: 1280px) and (max-width: 1439px) {
    .insta-iframe {
        height: 224px;
    }
}

@media (min-width:1440px) and (max-width:1900px) {
    .insta-iframe {
        height: 284px;

    }
}

@media(min-width: 1900px) {
    .insta-iframe {
        height: 353px;
    }

}


@media (max-width:767px) {
    .venice_customer_review_slide_img img {
        width: 120px;
        margin: 0 auto 0;
    }
    .venice_hero_slider .banner-content {
        padding: 0;
        margin-top: 0;
    }

    .venice_hero_img img {
        height: 100%;
        width: 100%;
    }

    .venice_hero_slider h1 {
        font-size: 37px;
        color: #fff;
        margin: 0;
    }

    .venice_hero_slider p {
        color: #fff;
    }

    .text-center.banner-content:before {
        content: "";
        background: #0000002f;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    /* ===================== */

    .vanice-DiamondRing {
        padding-top: 40px;
        padding-bottom: 50px;
    }

    .vanice-DiamondRing .row {
        flex-direction: column-reverse;
    }

    .vanice-DiamondRing .WordStyle {
        display: none;
    }

    .vanice-DiamondRing img {
        position: relative;
        top: 0;
        max-width: 70%;
        margin: auto;
        display: table;
    }

    .vanice-DiamondRing .textBox {
        position: relative;
        top: 0;
        left: 0;
    }

    .vanice-DiamondRing .vanice-DiamondRing-title {
        font-size: 35px;
        line-height: 40px;
    }

    /* =============== */
    .DiamondCollection {
        padding: 0;
        background-image: none;
    }

    .DiamondCollection .contentBox {
        padding-left: 0;
        padding-top: 0;
    }

    .DiamondCollection .contentBox h2 {
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 7px;
        text-align: center;
    }

    .DiamondCollection .contentBox {
        text-align: center;
    }

    .DiamondCollection .row {
        flex-direction: column-reverse;
    }

    /* ====================== */

    .sectionTitle {
        font-size: 30px;
        line-height: 1.3;
        margin-bottom: 0;
    }

    .vanice-DiamondRing.ExploreCollection {
        padding-top: 70px;
        padding-bottom: 0;
    }

    .ExploreCollection_innerBox {
        padding-top: 20px;
        padding-bottom: 0;
    }

    .vanice-DiamondRing.ExploreCollection img,
    .vanice-DiamondRing.ExploreCollection .textBox {
        max-width: 92%;
        margin: auto;
        margin-top: 15px;
    }

    .vanice-DiamondRing.ExploreCollection .ExploreCollection_innerBox.container:nth-child(odd) .row {
        flex-direction: column-reverse;
    }

    /* ================== */
    .venice_DiamondShape {
        padding: 60px 0;
    }

    .venice_DiamondShape .swiper {
        padding: 20px 30px;
    }

    .venice_Customer .venice_Customer_inner {
        padding: 30px 0px;
        max-height: 100%;
        margin-bottom: 20px;
    }

    .venice_Customer .venice-custome-container.container {
        padding: 30px;
    }

    .venice_Customer .venice-custome-container .slick-arrow {
        width: 25px;
        height: 25px;
        padding: 1px;
    }

    .venice_Customer .venice-custome-container .slick-dots li {
        width: 15px;
        height: 15px;
    }

    .venice_Customer .venice-custome-container .slick-dots {
        bottom: -11px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .venice_customer_review_slide_img img {
        width: 120px;
        margin: 0 auto 0;
    }
    .venice_hero_slider h1 {
        color: #fff;
    }

    .venice_hero_slider p {
        color: #fff;
    }

    .venice_hero_slider .banner-content {
        padding: 35px;
        margin: 0;
    }

    .VeniceTheme {
        padding: 40px;
    }

    .vanice-DiamondRing img {
        position: absolute;
        top: -95px;
    }

    .venice_hero_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .textBox {
        position: absolute;
        top: 25%;
        left: 20%;
    }

    .vanice-DiamondRing {
        padding-bottom: 100px;
        padding-top: 20px;
    }

    .vanice-DiamondRing .vanice-DiamondRing-title {
        font-size: 35px;
    }

    /* ================= */
    .DiamondCollection {
        padding: 0;
        margin: 40px 0;
    }

    .DiamondCollection .contentBox h2 {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 10px;
        margin-top: 0;
    }

    .DiamondCollection .contentBox {
        padding-left: 0;
        padding-top: 0;
    }

    .videoBox {
        position: relative;
        top: 50px;
    }

    section.vanice-DiamondRing.ExploreCollection img {
        position: static;
    }

    .ExploreCollection_innerBox.container .row {
        align-items: center;
    }

    .ExploreCollection_innerBox {
        padding: 0px 0 50px;
    }

    .venice_DiamondShape {
        padding: 0px 0 80px;
    }

    .venice_Customer .venice_Customer_inner {
        padding: 45px 0;
    }

    .venice_Customer .venice-custome-container.container {
        padding: 40px;
    }
}

@media (min-width:991px) and (max-width:1279px) {
    .videoBox {
        position: relative;
        top: 76px;
    }

    .VeniceTheme {
        padding: 0 50px;
    }

    .DiamondCollection .contentBox h2 {
        font-size: 45px;
        letter-spacing: 20px;
    }

    .DiamondCollection {
        padding: 0px;
    }

    .venice_Customer .venice_Customer_inner {
        padding: 50px 0;
    }



    .vanice-DiamondRing .WordStyle {
        font-size: 212px;
        line-height: 281px;
    }

    .textBox {
        position: absolute;
        top: 24%;
        left: 60px;
    }
}

@media (min-width:991px) and (max-width:1199px) {
    .DiamondCollection .contentBox h2 {
        font-size: 40px;
        letter-spacing: 10px;
    }
}

@media (min-width:1280px) and (max-width:1439px) {
    .videoBox {
        position: relative;
    }

    .VeniceTheme {
        padding: 0 60px 60px;
    }

    .DiamondCollection .contentBox h2 {
        font-size: 45px;
        letter-spacing: 20px;
    }

    .DiamondCollection {
        margin-top: 100px;
        padding: 50px;
    }

    .venice_Customer .venice_Customer_inner {
        padding: 50px 0;
    }

    .venice_DiamondShape .container-fluid{
        max-width: 1300px;
        margin: auto;
    }

    .venice_footer_left,
    .venice_footer_right {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .venice_footer_left_inner {
        padding-left: 70px;
    }
    .popupImageLoad .modal-dialog {
        max-height: 500px;
        max-width: 500px;
    }
}

@media (min-width:1440px) and (max-width:1900px) {
    .popupImageLoad .modal-dialog {
        max-height: 600px;
        max-width: 600px;
    }
}
@media (min-width:1440px) and (max-width:1920px) {
    
    .videoBox {
        position: relative;
    }

    .VeniceTheme {
        padding: 0 60px 60px;
    }

    .DiamondCollection .contentBox h2 {
        font-size: 50px;
        letter-spacing: 25px;
    }

    .DiamondCollection {
        padding: 65px;
        margin-top: 120px;
    }

    .venice_hero_slider {
        min-height: 780px;
    }
}

@media only screen and (min-width:1025px) and (max-width:1441px) {
    .venice_hero_slider {
        min-height: 650px;
    }
}

@media only screen and (max-width:1024px) and (min-width: 993px) {
    .vanice-DiamondRing img {
        top: -150px;
    }
}

